import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const CustomerContactCenter = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="CustomerContactCenter"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Our customer experience transformation centers combine domain experience, business process expertise and technology to enhance contact center operations for:</p>
                        <ul className="circle-list">
                            <li className="mt-2">The Customer – to improve experience and engagement</li>
                            <li className="mt-2">The Agent – to empower with insights & intelligence</li>
                            <li className="mt-2">The Organization – to drive competitive advantage at lower cost</li>
                        </ul>
                        <h6 className="mt-2">25 years of industry experience</h6>
                        <h6 className="mt-2">Certified 24x7 global centers</h6>
                        <h6 className="mt-2">Multi-lingual capabilities</h6>
                        <p className="mt-3">Our CX transformation capabilities support multiple industries and include customers like Fortune 100 Global Banks, Top 20 U.S. Banks, large Insurance providers and Travel/ Hospitality companies.</p>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query CustomerContactCenterPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

CustomerContactCenter.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default CustomerContactCenter;
